<template>
  <section class="my-16 text-center">
    <div class="mt-2 text-2xl sm:text-4xl font-semibold tracking-tight text-gray-900">Follow us</div>
    <div class="flex justify-center mt-4">
      <a target="_blank" href="https://www.instagram.com/drumrudimentsapp/">
        <SvgIg class="h-10 w-10 mr-4" />
      </a>
      <a target="_blank" href="https://www.facebook.com/drumrudimentsapp/">
        <SvgFb class="h-10 w-10 mr-4" />
      </a>
      <a target="_blank" href="https://www.youtube.com/channel/UCl30qjRjHIKnj370VpE-aTQ">
        <SvgYt class="h-10 w-10" />
      </a>
    </div>
  </section>
</template>